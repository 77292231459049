<template>
  <!-- This template is used for the mobile deletion of CME -->
  <div>
    <transition name="fade" mode="out-in">
      <!-- Loader before Session is loaded -->
      <aad-loader
        key="loading"
        v-if="!session || !session.SessionCode"
      ></aad-loader>

      <!-- Loaded from during CME Deletion -->
      <aad-loader
        key="delete-loading"
        v-else-if="surveyDeleting"
        message="Deleting CME Claim, Please Wait"
      ></aad-loader>

      <!-- Screen if the user decides to cancel, simple message -->
      <div key="cancelled" v-else-if="isCancelled">
        <aad-title>{{ session.SessionCode }} - {{ session.Name }}</aad-title>
        <div class="text-center">
          <aad-icon name="close-circle" class="warning-icon mb-2"></aad-icon>
          <h3>Your CME Deletion has been cancelled.</h3>
        </div>
      </div>

      <!-- Confirmation on a successful deletion -->
      <div key="survey-deleted" v-else-if="surveyDeleted">
        <aad-title>{{ session.SessionCode }} - {{ session.Name }}</aad-title>
        <div class="text-center">
          <aad-icon
            name="checkmark-circle-filled"
            class="submitted-icon mb-2"
          ></aad-icon>
          <h3>Your CME claim has been deleted.</h3>
        </div>
      </div>

      <!-- Error Message Display -->
      <div key="error" v-else-if="errorMessage">
        <h3 class="text-center py-5">
          Something went wrong, please try again.
        </h3>
      </div>

      <!-- -->
      <div v-else key="main-screen" class="w-100 py-4">
        <div>
          <div v-if="session">
            <aad-title
              >{{ session.SessionCode }} - {{ session.Name }}</aad-title
            >
          </div>
          <div v-else>
            <aad-title>Overall Feedback</aad-title>
          </div>
        </div>
        <div class="text-center">
          <aad-icon
            name="warning-circle-filled"
            class="warning-icon mb-2"
          ></aad-icon>
          <h3>
            Are you sure you would like to delete your CME claim for
            {{ session.SessionCode }} - {{ session.Name }}?
          </h3>
          <button @click="deleteCME" class="btn btn-warning-outline mr-2">
            Yes, Delete CME
          </button>
          <button @click="cancelDeletion" class="btn btn-primary-solid">
            No, Cancel
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AadLoader from "../components/AadLoader.vue";
import { db } from "@/main"; // Import the firebase db for speedy mobile embed loads
import Analytics from "@/mixins/analytics.js";

export default {
  data() {
    return {
      sessionId: this.$route.params.id, // Get sessionId from the url
      session: {
        // Session is either loaded from firebase or vuex, starts empty
      },
      errorMessage: "", // Will be populated and prevent page from submitting

      surveyDeleting: false, // Used for loading animation
      surveyDeleted: false, // Used to pop loading screen
      isMobileView: this.$route.query.mobileView || false,
      isCancelled: false,
    };
  },
  metaInfo() {
    return {
      title: this.session ? this.session.Name : "CME Deletion", // Used to set <title> attribute
    };
  },
  mounted() {
    // Load necessary Data
    this.loadCMEClaims();

    // Load the session we're deleting

    // For mobile embed purposes, instead of loading all of the sessions
    // instead we're going to call out to firestore for 1 record.

    db.collection("Sessions")
      .doc(this.sessionId)
      .get()
      .then((session) => {
        this.session = session.data();
      });
  },
  methods: {
    // Action dispatch from Vuex
    loadCMEClaims() {
      this.$store.dispatch("loadAttendeeCMEClaims");
    },
    cancelDeletion() {
      // Log to analytics
      Analytics.logLinkClick(
        "cme_cancel",
        "/cme",
        this.format == "compact" ? "session_details" : "cme"
      );

      this.isCancelled = true;
    },
    deleteCME() {
      this.surveyDeleting = true;

      let payload = {
        MeetingCode: this.getMeetingCode,
        SessionCode: this.session.SessionCode,
        RegCode: this.loggedInUser.registrationCode,
      };

      // Delete from server
      // Submit to server
      fetch(`https://meetings.aad.org/api/eval/delete/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          // Check if the server returned a 200 OK
          if (response.status == 200) {
            return response.json();
          } else {
            this.throwError("ERROR WITH REQUEST TO SERVER");
            throw new Error();
          }
        })
        .then((data) => {
          // Make sure that the data is validated on server

          if (data.successful) {
            // If successful, commit to firestore
            // Dispatch method to save to firestore
            this.$store.dispatch("deleteAttendeeCMEClaim", payload).then(() => {
              // After it's been submitted
              this.surveyDeleting = false;
              this.surveyDeleted = true;
            });

            // Log to analytics
            // Log to analytics
            Analytics.logEvent("aad_cme_click", {
              action_type: "delete",
              session_code: this.session.SessionCode,
              screen_origin: "cme_delete",
            });
          } else {
            // Error Message from the Server's Validation
            this.throwError(
              data.publicErrorMessage ||
                "Data was not marked as successful by server"
            );

            // Log error to analytics
            Analytics.logException(
              "data_incorrect",
              data.publicErrorMessage ||
                "Data was not marked as successful by server",
              "cme_delete"
            );
          }
        })
        .catch(() => {
          this.throwError("Error");
        });
    },
    throwError: function (errorMessage) {
      this.surveyDeleting = false;
      this.errorMessage = errorMessage;
      console.log(this.errorMessage);

      // Log error to analytics
      Analytics.logException("cme_delete_error", errorMessage, "cme_delete");
    },
  },
  components: {
    AadLoader,
  },
  computed: {
    ...mapGetters(["getMeetingCode", "loggedInUser"]),
  },
  watch: {
    $route() {
      // react to route changes to 'refresh' on new IDs and clearing it...
      this.sessionId = this.$route.params.id;
    },
  },
};
</script>

<style scoped>
.submitted-icon {
  color: #03821b;
  width: 100px;
  height: 100px;
}

.warning-icon {
  color: #cc1d1d;
  width: 100px;
  height: 100px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
