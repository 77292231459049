import { render, staticRenderFns } from "./CMEDelete.vue?vue&type=template&id=38277674&scoped=true&"
import script from "./CMEDelete.vue?vue&type=script&lang=js&"
export * from "./CMEDelete.vue?vue&type=script&lang=js&"
import style0 from "./CMEDelete.vue?vue&type=style&index=0&id=38277674&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38277674",
  null
  
)

export default component.exports